// url base para as requisições [12/08/2024]

const url = 'https://apilmbeta.lawmetrix.com.br';



const headers = {
    'Content-Type': 'application/json',
};



//requisição de login  [12/08/2024]
 
export async function LoginRequest(username, password){
    try {
            const response = await fetch(url + '/api/v1/login', {
                method: 'POST',
                headers,
                body: JSON.stringify({ username: username, password: password }),
      });
  
      
      const data = await response.json();
      return data;
    } catch (error) {
        return error;
    }
};

// =============================== validação de email =====================================

export async function LoginSendToken(email){
    try {
            const response = await fetch(url + '/api/v1/sendtoken', {
                method: 'POST',
                headers,
                body: JSON.stringify({ email: email }),
      });
  
      
      const data = await response.json();
      return data;
    } catch (error) {
        return error;
    }
};

export async function LoginWithToken(userId, token){
    try {
        const response = await fetch(url + '/api/v1/authenticatetoken', {
            method: 'POST',
            headers,
            body: JSON.stringify({ user_id: userId, token: token }),
        });
        
        
        const data = await response.json();
        return data;
    } catch (error) {
        return error;
    }
};

// ==================== requisição de processo por numero do processo  [12/08/2024] ===========================

export async function ConsultProcess(processo, type) {
    const baseUrl = url+`/api/v1/processo/${type}/${processo}`
    try {
        const response = await fetch(baseUrl, {
            method: 'GET',
            headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return { error: error.message };
    }
}

export async function ConsultTermos(processo) {
    try {
        const response = await fetch(url + `/api/v1/processos/${processo}/termos`, {
            method: 'GET',
            headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return { error: error.message };
    }
}


export async function ConsultMovimentacao(processo) {
    try {
        const response = await fetch(url + `/api/v1/processos/${processo}/timeline`, {
            method: 'GET',
            headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return { error: error.message };
    }
}


export async function ConsultCPF(processo, type) {
    const baseUrl = url+`/api/v1/${type}/${processo}`
    try {
        const response = await fetch(baseUrl, {
            method: 'GET',
            headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return { error: error.message };
    }
}

// ========================= requisição usuarios ===============================================

export const fetchUsers = async () => {
    const baseUrl = url+'/api/v1/users'
   try {
       const response = await fetch( baseUrl , {
           method: 'GET',
           headers,
       });

       if (!response.ok) {
           throw new Error(`HTTP error! status: ${response.status}`);
       }

       const data = await response.json();
       return data;
   } catch (error) {
       console.error('Erro ao buscar dados:', error);
       throw error;
   }
};

export const saveUser = async (userData, isEditing) => {

    try {
        const baseUrl = isEditing ? `${url}/api/v1/users/${userData.id}` : `${url}/api/v1/users`;
        const method = isEditing ? 'PUT' : 'POST';

        const response = await fetch(baseUrl, {
            method,
            headers,
            body: JSON.stringify(userData),
        });

        if (!response.ok) {
            throw new Error('Erro ao salvar os dados no banco de dados');
        }

        return await response.json();
    } catch (error) {
        console.error('Erro ao salvar os dados:', error);
        throw error;
    }
};

export const removeUser = async (user) => {
    const baseUrl = url+`/api/v1/users/${user.Ap_Id}`
    try {
        const updatedUser = {
            ...user,
            status: 'Inativo',
        };

        const response = await fetch( baseUrl, {
            method: 'PUT',
            headers,
            body: JSON.stringify(updatedUser),
        });

        if (!response.ok) {
            throw new Error('Erro ao remover o usuario');
        }

        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
};


// ===================================== reuisição empresas ===================================

export const fetchEmpresas = async () => {
     const baseUrl = url+`/api/v1/empresas`
    try {
        const response = await fetch( baseUrl , {
            method: 'GET',
            headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Erro ao buscar dados:', error);
        throw error;
    }
};

export const  updateEmpresa = async (id, updatedData) => {
    const baseUrl = url+`/api/v1/empresas/${id}`
    try {
        const response = await fetch(baseUrl, {
            method: 'PATCH',
            headers,
            body: JSON.stringify(updatedData),
        });

        if (!response.ok) {
            throw new Error('Erro ao atualizar os dados');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Erro ao atualizar os dados:', error);
        throw error;
    }
};

export const addEmpresa = async (newData) => {
    const baseUrl = url+`/api/v1/empresas`
    try {
        const response = await fetch( baseUrl, {
            method: 'POST',
            headers,
            body: JSON.stringify(newData),
        });

        if (!response.ok) {
            throw new Error('Erro ao salvar os dados');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Erro ao salvar os dados:', error);
        throw error;
    }
};

export const removeEmpresas = async (empresa) => {
    const baseUrl = url+`/api/v1/empresas/${empresa.id}`
    try {
        const updatedEmpresa = {
            ...empresa,
            ativo: 'N',
        };

        const response = await fetch( baseUrl, {
            method: 'PATCH',
            headers,
            body: JSON.stringify(updatedEmpresa),
        });

        if (!response.ok) {
            throw new Error('Erro ao remover a empresa');
        }

        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
};

//========================= requisição advogados =================================

export const fetchAdvogados = async () => {
    const baseUrl = url+`/api/v1/advogados`
    try {
        const response = await fetch( baseUrl, {
            method: 'GET',
            headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Erro ao buscar dados:', error);
        throw error;
    }
};

export const saveAdvogado = async (advogadoData, isEditing) => {
    try {
        const baseUrl  = isEditing ? `${url}/api/v1/advogados/${advogadoData.id}` : `${url}/api/v1/advogados`;
        const method = isEditing ? 'PATCH' : 'POST';

        const response = await fetch(baseUrl, {
            method,
            headers,
            body: JSON.stringify(advogadoData),
        });

        if (!response.ok) {
            throw new Error('Erro ao salvar os dados no banco de dados');
        }

        return await response.json();
    } catch (error) {
        console.error('Erro ao salvar os dados:', error);
        throw error;
    }
};

export const removeAdvogado = async (advogado) => {
    const baseUrl = url+`/api/v1/advogados/${advogado.id}`
    try {
        const updatedAdvogado = {
            ...advogado,
            ativo: 'N',
        };

        const response = await fetch( baseUrl, {
            method: 'PATCH',
            headers,
            body: JSON.stringify(updatedAdvogado),
        });

        if (!response.ok) {
            throw new Error('Erro ao atualizar o status do advogado');
        }

        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
};

// ==================================== Logs ============================================

export function Logis(payload){
    try {
            const response =  fetch(url + '/api/v1/logs', {
                method: 'POST',
                headers,
                body: JSON.stringify({ route: payload.route, descricao: payload.descricao,  user_id: payload.user_id }),
      });
  
      
      const data = response.json();
      return data
    } catch (error) {
        return error;
    }
};