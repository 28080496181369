import React, { useState } from 'react';
import * as C from './ProcessoDetailStyles';
import "../../../../styles/variables.css";

//componente de renderização do corpo do processo [12/08/2024]

const ProcessoDetalhes = ({ process, handleCPFSearch, hasSubPermission }) => {
    const [showTags, setShowTags] = useState(true);
    const {
        Ativos = [],
        Passivos = [],
        AdvAtivos = [],
        AdvPassivos = [],
        Andamento = ["Não informado"],
        Assuntos = [],
        NumProcesso = ["N/A"],
        StatusSN = ["Indisponível"],
        ValorCausa = [],
        ValorSentenca = [],
        OrgaoJulgador = ["Não informado"],
        Distribuido = ["Data indisponível"],
        Autuado = ["Data indisponível"],
        DataAndamento = ["Data indisponível"],
        Instancia = ["Indisponível"],
        Classe = ["Indisponível"],
        Termos =[{}],
    } = process;

    const toggleTags = () => {
        setShowTags(!showTags);
    };

    const handleCopyProcessDetails = () => {
        let DataIntimacao = formatDate(DataAndamento[0]);
        let StatusSNforCopy = StatusSN.some(status => status.includes("Erro de Busca"))? "Erro de Busca" : StatusSN[0] || "Indisponível"
        let DataDitribuido = Distribuido ? new Date(Distribuido).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' }) : 'Informação não disponível';
        let processDetails = `
        Número do Processo: ${NumProcesso[0] || "N/A"}  
        Status: ${StatusSNforCopy || "Indisponível"}
        
        ${Instancia[0] || "Instância não informada"}
        
        Órgão Julgador: ${OrgaoJulgador[0] || "Não informado"}  
        Data Distribuído: ${ DataDitribuido }  
        Data Autuado: ${Autuado[0] || "Data indisponível"}
        
        Valor da Causa: ${ValorCausa[0] ? `R$ ${ValorCausa[0]}` : "Valor Indisponível"}
        Valor da Sentença: ${ValorSentenca[0] ? `R$ ${ValorSentenca[0]}` : "Valor Indisponível"}
        
        Andamento do Processo: ${Andamento[0] || "Não informado"}  
        Última Intimação: ${DataIntimacao || "Data indisponível"}
        
        Polo Ativo:
        `;
    
        Ativos.forEach(ativo => {
            processDetails += `${ativo.NomeAtivo || "Nome não disponível"} (${ativo.CPFAtivo ? ativo.CPFAtivo : "CPF não disponível"})\n`;
        });
    
        processDetails += `
        
        Advogados Polo Ativo:
        `;
    
        AdvAtivos.forEach(advAtivo => {
            processDetails += `${advAtivo.AdvogadoAtivo || "Nome não disponível"}\n`;
        });
    
        processDetails += `
        
        Polo Passivo:
        `;
    
        Passivos.forEach(passivo => {
            processDetails += `${passivo.NomePassivo || "Nome não disponível"} (${passivo.CPFPassivo ? passivo.CPFPassivo : "CNPJ não disponível"})\n`;
        });
    
        processDetails += `
        
        Advogados Polo Passivo:
        `;
    
        AdvPassivos.forEach(advPassivo => {
            processDetails += `${advPassivo.AdvogadoPassivo || "Nome não disponível"}\n`;
        });
    
        if (Assuntos && Assuntos.length > 0 && Assuntos[0].Assunto) {
            processDetails += `\nAssuntos:\n`;
            Assuntos.sort(a => (a.Principal === "S" ? -1 : 1)).forEach(assunto => {
                processDetails += `${assunto.Assunto}\n`;
            });
        }
    
        processDetails += `
        
        Andamento do Processo: ${Andamento[0] || "Não informado"}
        Última Intimação: ${DataIntimacao || "Data indisponível"}
        `;
    
        navigator.clipboard.writeText(processDetails);
    };

    const formatCPFOrCNPJ = (value) => {
        const numericValue = value.replace(/[^\d]/g, '');
    
        if (numericValue.length === 11) {
            // Formata como CPF
            return numericValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        } else if (numericValue.length === 14) {
            // Formata como CNPJ
            return numericValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        }
    
        // Se não for CPF ou CNPJ, retorna o valor original
        return null;
    };

    const isValidDate = (date) => {
        return !isNaN(Date.parse(date));
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        
        // Se a data não for válida, retorna uma string vazia ou a data original
        if (isNaN(date)) return dateString;
    
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Os meses começam em 0
        const year = date.getFullYear();
    
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
    
        return `${day}/${month}/${year} ${hours}:${minutes}`;
    };

    const isValidUrl = (url) => {
        try {
            new URL(url);
            return true;
        } catch (error) {
            return false;
        }
    };
    

    return (
        <C.ProcessSection>
            <C.ProcessHeader>
                <C.ProcessNumber onClick={handleCopyProcessDetails}>
                    Número do Processo: {NumProcesso[0] || "N/A"}
                </C.ProcessNumber>
                {hasSubPermission(1, 2) && (
                    <C.Status $status={StatusSN[0]}>
                        {Array.isArray(StatusSN) && StatusSN.some(status => status && status.includes("Erro de Busca"))
                        ? "Erro de Busca"
                        : StatusSN[0] || "Indisponível"}
                    </C.Status>
                )}
            </C.ProcessHeader>

            <C.Tags>
                <C.Tag>{Instancia[0] || "Indisponiível"}</C.Tag>
            </C.Tags>

            <C.ProcessInfo>
                <C.ProcessDetail><strong>Órgão Julgador:</strong> {OrgaoJulgador[0] || "Não informado"}</C.ProcessDetail>
                <C.ProcessDetail><strong>Classe:</strong> {Classe[0] || "Não informado"}</C.ProcessDetail>
                <C.ProcessDetail>
                    <strong>Data Distribuído:</strong> 
                    {isValidDate(Distribuido) ? 
                        new Date(Distribuido).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' }) : 
                        '-'}
                </C.ProcessDetail>

                <C.ProcessDetail>
                    <strong>Data Autuado:</strong> 
                    {isValidDate(Autuado[0]) ? 
                        new Date(Autuado[0]).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' }) : 
                        '-'}
                </C.ProcessDetail>
                <C.ProcessDetail><strong>Valor da Causa:</strong> {ValorCausa[0] ? `R$ ${ValorCausa[0]}` : "Valor Indisponível"}</C.ProcessDetail>
                <C.ProcessDetail><strong>Valor da Sentença:</strong> {ValorSentenca[0] ? `R$ ${ValorSentenca[0]}` : "Valor Indisponível"}</C.ProcessDetail>
            </C.ProcessInfo>

            {hasSubPermission(1,2) && <C.PolosContainer>
                 <C.Polo>
                    <strong>Polo Ativo:</strong>
                    <C.NamesList>
                    {Ativos.length > 0 ? Ativos.map((ativo, index) => (
                        <C.MapList key={index}>
                            <C.BoxName>
                                <C.Name>{ativo.NomeAtivo || "Nome não disponível"}</C.Name>
                                
                                {ativo.LinkAssociado && isValidUrl(ativo.LinkAssociado) ? (
                                    ativo.LinkAssociado.toLowerCase().includes('rpv') ? (
                                        <C.PrecatórioLink 
                                            href={hasSubPermission(1, 9) ? ativo.LinkAssociado.replace(/( |%20)?rpv/gi, '') : undefined}
                                            onClick={hasSubPermission(1, 9) ? null : (e) => e.preventDefault()} // Previne o clique se não tiver permissão
                                            target={hasSubPermission(1, 9) ? "_blank" : undefined} 
                                            rel={hasSubPermission(1, 9) ? "noopener noreferrer" : undefined}
                                            style={{ cursor: hasSubPermission(1, 9) ? 'pointer' : 'not-allowed'}}
                                        >
                                            RPV
                                        </C.PrecatórioLink>
                                    ) : (
                                        <C.PrecatórioLink 
                                            href={hasSubPermission(1, 9) ? ativo.LinkAssociado : undefined}
                                            onClick={hasSubPermission(1, 9) ? null : (e) => e.preventDefault()} // Previne o clique se não tiver permissão
                                            target={hasSubPermission(1, 9) ? "_blank" : undefined} 
                                            rel={hasSubPermission(1, 9) ? "noopener noreferrer" : undefined}
                                            style={{ cursor: hasSubPermission(1, 9) ? 'pointer' : 'not-allowed' }}
                                        >
                                            Precatório
                                        </C.PrecatórioLink>
                                    )
                                ) : null}
                            </C.BoxName>
                            <C.CPFType 
                                onDoubleClick={() => {
                                    const cpfCnpj = ativo.CPFAtivo.replace(/[^\d]/g, '');
                                    const isCPF = /^\d{11}$/.test(cpfCnpj);
                                    const isCNPJ = /^\d{14}$/.test(cpfCnpj);

                                    if (isCPF) {
                                        handleCPFSearch(ativo.CPFAtivo, 'cpf');
                                    } else if (isCNPJ) {
                                        handleCPFSearch(ativo.CPFAtivo, 'cnpj');
                                    }
                                }}
                            >
                                 {ativo.CPFAtivo && formatCPFOrCNPJ(ativo.CPFAtivo) ? 
                                    `${formatCPFOrCNPJ(ativo.CPFAtivo)}` : 
                                    "CPF não disponível"}
                            </C.CPFType>
                        </C.MapList>
                    )) : <C.MapList>Informações não disponíveis</C.MapList>}
                    </C.NamesList>
                </C.Polo>

                <C.Polo>
                    <strong>Polo Passivo:</strong>
                    <C.NamesList>
                    {Passivos.length > 0 ? Passivos.map((passivo, index) => (
                        <C.MapList key={index}>
                            <C.Name>{passivo.NomePassivo || "Nome não disponível"}</C.Name>
                            <C.CPFType
                                onDoubleClick={() => {
                                    const cpfCnpj = passivo.CPFPassivo.replace(/[^\d]/g, '');
                                    const isCPF = /^\d{11}$/.test(cpfCnpj);
                                    const isCNPJ = /^\d{14}$/.test(cpfCnpj);

                                    if (isCPF) {
                                        handleCPFSearch(passivo.CPFPassivo, 'cpf');
                                    } else if (isCNPJ) {
                                        handleCPFSearch(passivo.CPFPassivo, 'cnpj');
                                    }
                                }}
                            >
                                {passivo.CPFPassivo && formatCPFOrCNPJ(passivo.CPFPassivo) ? 
                                    `${formatCPFOrCNPJ(passivo.CPFPassivo)}` : 
                                    "CNPJ não disponível"}
                            </C.CPFType>
                        </C.MapList>
                    )) : <C.MapList>Informações não disponíveis</C.MapList>}
                    </C.NamesList>
                </C.Polo>
            </C.PolosContainer>}

            {hasSubPermission(1,2) && <C.AdvogadosContainer>
                <C.Polo>
                    <strong>Advogados Polo Ativo:</strong>
                    <C.NamesList>
                    {AdvAtivos.length > 0 ? AdvAtivos.map((adv, index) => (
                        <C.Name key={index}>{adv.AdvogadoAtivo || "Nome não disponível"}</C.Name>
                    )) : "Nenhuma informação disponível"}
                    </C.NamesList>
                </C.Polo>

                <C.Polo>
                    <strong>Advogados Polo Passivo:</strong>
                    <C.NamesList>
                    {AdvPassivos.length > 0 ? AdvPassivos.map((adv, index) => (
                        <C.Name key={index}>{adv.AdvogadoPassivo || "Nome não disponível"}</C.Name>
                    )) : "Nenhuma informação disponível"}
                    </C.NamesList>
                </C.Polo>
            </C.AdvogadosContainer>}

            {Assuntos && Assuntos.length > 0 && Assuntos[0].Assunto && (
                <C.TagsContainer>
                    <strong onClick={toggleTags}>Assuntos:</strong>
                    <C.TagsWrapper $show={showTags}>
                    {Assuntos.length > 0 ? Assuntos.sort((a) => (a.Principal === "S" ? -1 : 1)).map((assunto, index) => (
                        <C.Tag key={index}>{assunto.Assunto}</C.Tag>
                    )) : ""}
                    </C.TagsWrapper>
                </C.TagsContainer>
            )}        

            <C.ProcessProgress>
                <strong>Andamento do Processo:</strong> {Andamento[0] || "Não informado"}
                <br />
                <strong>Última Intimação:</strong> {DataAndamento[0] ? new Date(DataAndamento[0]).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' }) : 'Informação não disponível'}
            </C.ProcessProgress>

            {hasSubPermission(1,3) && Termos && Termos.length > 0 && (<C.ProcessProgress>
                <strong>Termos:</strong> {Termos[0].Termos || "Não informado"}
                <br />
                <strong>Atualizo em:</strong> {Termos[0].DataInclusão ? new Date(Termos[0].DataInclusão).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' }) : 'Informação não disponível'}
            </C.ProcessProgress>
            )}
        </C.ProcessSection>
    );
};

export default ProcessoDetalhes;
