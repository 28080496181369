import React, { useState } from "react";
import "../../../styles/variables.css";
import * as C from "./UploadsStyles";
import Button from "../../../components/button/Button";
import Input from "../../../components/inputs/Input";
import { AiFillFilePdf } from "react-icons/ai";

// import Loader from "../../../components/loader/LoaderSpin";

//componente de renderização do upload [12/08/2024]

const Uploads = () =>{
    const [file, setFile] = useState(null);
    const [error, setError] = useState("Selecione ou arraste um arquivo PDF");
    const [isValid, setIsValid] = useState(false);


    //função para verificar se o arquivo é um pdf [12/08/2024]

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            if (selectedFile.type === "application/pdf") {
                setFile(selectedFile);
                setError(selectedFile.name);
                setIsValid(true)
            } else {
                setError("Por favor, selecione um arquivo PDF.");
                setFile(selectedFile);
                setIsValid(false)
            }
        }
    };

    //função para verificar se o arquivo  ARRASTADO é um pdf [12/08/2024]

    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0];
        if (droppedFile) {
            if (droppedFile.type === "application/pdf") {
                setFile(droppedFile);
                setError(droppedFile.name);
                setIsValid(true)
            } else {
                setError("Por favor, selecione um arquivo PDF.");
                setFile(droppedFile);
                setIsValid(false)
            }
        }
    };

    //previne recarregamento da pagina [12/08/2024]

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    //função para enviar o arquivo [12/08/2024]

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!file) {
            setError("Nenhum arquivo selecionado.");
            return;
        }
        if (file.type !== "application/pdf") {
            setError("O arquivo selecionado não é um PDF.");
            return;
        }
        console.log("Arquivo PDF selecionado:", file);
    };

    return (
        <C.UploadContainer>
        <C.FormBox onSubmit={handleSubmit} onDrop={handleDrop} onDragOver={handleDragOver}>
          <C.InputFileBox>
            <Input
              type="file"
              id="pdf-upload"
              onChange={handleFileChange}
              autocomplete="off"
              className="file-input"
            />
            <C.CircleLoppingBox>
             <C.CircleLopping htmlFor="pdf-upload" $isValid={isValid}>
                <C.LabelBox htmlFor="pdf-upload" $isValid={isValid}>
                    <AiFillFilePdf /> 
                </C.LabelBox>
                <C.LabelInitBox htmlFor="pdf-upload" $isValid={isValid}>
                    Começar
                </C.LabelInitBox>
             </C.CircleLopping>
            </C.CircleLoppingBox>
          </C.InputFileBox>
            {error && <C.FormBooble>
                { error } 
            </C.FormBooble>}
            <Button 
                Text="Enviar"
                type="submit"
            />
        </C.FormBox>
      </C.UploadContainer>
    );
  };


export default Uploads