
import styled, { keyframes } from 'styled-components';




export const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 1px solid var(--color-gray);
  border-radius: 8px;
  width: 300px;
  margin: 0 auto;
  gap: 10px;
  background: var(--color-grayBlue);

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  input {
    margin-bottom: 10px;
  }

  p {
    color: red;
    margin-bottom: 10px;
  }

  button {
    max-width: 180px;
  }

`;

export const FormBox = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 5px;
  gap: 5px;
`;

export const InputFileBox = styled.div`
  input#pdf-upload {
    opacity: 0;
    position: absolute;
    z-index: -1;
    display: none;
  }
`;

export const LabelBox = styled.label`
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    fill: ${({ $isValid }) => ($isValid ? 'var(--color-variant-blue)' : 'var(--color-red)')};;
    font-size: 20px;
  }
  display: none;
  visibility: hidden;
  transition: visibility 0s, opacity 1s linear;
`;

export const LabelInitBox = styled.label`
  padding-bottom: 10px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ $isValid }) => ($isValid ? 'var(--color-variant-blue)' : 'var(--color-red)')};
  cursor: pointer;
  transition: visibility 0s, opacity 2s linear;
`;

const rotate = keyframes`
  to {
    transform: rotate(1turn);
  }
`;

export const CircleLoppingBox = styled.div`
  min-height: 160px;
`;

export const CircleLopping = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-white);
  width: 120px;
  aspect-ratio: 1;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  transition: 1s;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 8px solid var(--color-gray);
    border-right-color: ${({ $isValid }) => ($isValid ? 'var(--color-green)' : 'var(--color-red)')};
    animation: ${rotate} 2s infinite linear;
    z-index: 1;
    transition: 1s;
  }

  ${LabelBox} {
    visibility: ${({ $isValid }) => ($isValid ? 'visible' : 'hidden')};
    display: ${({ $isValid }) => ($isValid ? 'block' : 'none')};
    opacity: ${({ $isValid }) => ($isValid ? 1 : 0)};
  }

  ${LabelInitBox} {
    visibility: ${({ $isValid }) => ($isValid ? 'hidden' : 'visible')};
    display: ${({ $isValid }) => ($isValid ? 'none' : 'block')};
    opacity: ${({ $isValid }) => ($isValid ? 0 : 1)};
  }

  &:hover {
    width: 140px;
    ${LabelBox} {
      visibility: visible;
      display: block;
      opacity: 1;
    }
    ${LabelInitBox} {
      visibility: hidden;
      display: none;
      opacity: 0;
    }
  }
`;

export const FormBooble = styled.div`
  background: var(--color-white);
  font-size: 12px;
  text-align: center;
  padding: 5px;
  border: 1px solid var(--color-neutral);
  border-radius: 5px;
  position: relative;
  margin-bottom: 10px;

  &:after {
    content: '';
    position: absolute;
    bottom: 100%; 
    left: 50%; 
    transform: translateX(-50%);
    border-width: 8px; 
    border-style: solid;
    border-color: transparent transparent var(--color-neutral) transparent;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 100%; 
    left: 50%; 
    transform: translateX(-50%);
    border-width: 7px; 
    border-style: solid;
    border-color: transparent transparent var(--color-white) transparent;
    margin-bottom: -1px; 
  }
`;