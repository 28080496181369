import React, {  useContext, useState } from "react";

import * as C from "./HomeStyles";
import Header from "../../components/header/Header";
import Navbar from "../../components/navbar/Navbar";
import Processo from "../subPages/processos/Processo";
import Uploads from "../subPages/uploads/Uploads";
import ListProcess from "../../components/listProcess/ListProcess";
import useProcess from "../../hooks/useProcess";
import Advogados from "../subPages/advogados/Advogados";
import Usuarios from "../subPages/usuarios/Usuarios";
import Empresas from "../subPages/empresas/Empresas";
import { AuthContext } from "../../contexts/auth";
// import Relatorios from "../subPages/relatorios/Relatorios";


//componente pai para renderização de cada subpage [12/08/2024]


const Home = () =>{
    const {
        search,
        setSearch,
        processData,
        moveProcessData,
        noResults,
        history,
        loading,
        activeTab,
        retCPF,
        handleSearch,
        handleSearchClick,
        handleHistoryClick,
        handleTabChange,
        handleCPFSearch,
        handleHistoryCover,
    } = useProcess();

    const { permissions } = useContext(AuthContext);


    const [hamburgerClicked, setHamburgerClicked] = useState(() => {
        // Verifica se há um valor salvo no localStorage
        const navOptions = localStorage.getItem('hamburgerClicked');
        return navOptions === 'true'
    });

    const [AppIndicatorClicked, setAppIndicatorClicked] = useState((false))

    const [textPageInfo, setTextPageInfo] = useState(() => {
        // Verifica se há um valor salvo no localStorage
        const saved = sessionStorage.getItem('textPageInfo');
        return saved || 'Consultas';
    });
    
   
    const handleHamburgerClick = () => {
        setHamburgerClicked(prevState => !prevState);
        localStorage.setItem('hamburgerClicked', !hamburgerClicked)
    };

    const appIndicatorClick = () => {
        setAppIndicatorClicked(prevState => !prevState);
    };

    const handleNavLinkClick = (value) => {
        setTextPageInfo(value)
        sessionStorage.setItem('textPageInfo', value);
    }

    const navegHome = (processo) =>{
        setTextPageInfo('Consultas');
        sessionStorage.setItem('textPageInfo', 'Consultas');
        handleHistoryCover(processo); 
    }

    const hasPermission = (permissionId) => {
        const hasPerm = permissions && permissionId in permissions;
        if (!hasPerm && permissionId !== 1) {
            handleNavLinkClick("Consultas");
        }
        return hasPerm;
    };

    const hasSubPermission = (permissionId, permissionIndex) => {
        if (permissions[permissionId]?.subpermissao.includes(permissionIndex)) {
            return true;
        }
        return false;
    };

    return(
    <C.HomeContainer>
        <Header  Text={textPageInfo} onHamburgerClick={handleHamburgerClick} onAppIndicatorClick={appIndicatorClick}/>
            <C.MainContent>
                {!hamburgerClicked && <Navbar onNavLinkClick={handleNavLinkClick} userPermissions={permissions} />}
                <C.ArticleContent>
                    {textPageInfo === "Consultas" && <Processo 
                        search={search}
                        setSearch = {setSearch}
                        processData = { processData }
                        moveProcessData = { moveProcessData }
                        noResults = { noResults }
                        history = { history }
                        retCPF = { retCPF }
                        handleSearch = { handleSearch }
                        handleSearchClick = { handleSearchClick }
                        handleHistoryClick={  handleHistoryClick }
                        loading = { loading }
                        activeTab = {activeTab}
                        handleTabChange = {handleTabChange}
                        handleCPFSearch = { handleCPFSearch }
                        hasSubPermission = {hasSubPermission}
                        userPermissions={permissions}
                    /> }
                    {textPageInfo === "Uploads" && hasPermission(6) && <Uploads hasSubPermission = {hasSubPermission}/> }
                    {textPageInfo === "Advogados" && hasPermission(3) && <Advogados hasSubPermission = {hasSubPermission}/> }
                    {textPageInfo === "Empresas" && hasPermission(4) && <Empresas hasSubPermission = {hasSubPermission}/> }
                    {textPageInfo === "Usuários" && hasPermission(2) && <Usuarios /> }
                    {/* {textPageInfo === "Relatórios"  && <Relatorios 
                        search={search}
                        setSearch = {setSearch}
                        handleSearch = { handleSearch }
                        handleCPFSearch = { handleCPFSearch }
                        handleSearchClick = { handleSearchClick }
                        onNavLinkClick={handleNavLinkClick}
                    />} */}
                </C.ArticleContent>
            </C.MainContent>
        {AppIndicatorClicked && <ListProcess history={ history } handleHistoryClick={ navegHome }/>}
    </C.HomeContainer>
    )
}


export default Home